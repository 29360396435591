import axios from "axios";

//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        // logger.log(error);
        // toast.error("An unexpected error occurrred.");
    }

    return Promise.reject(error);
});

function addDefaultHeader(key, value) {
    axios.defaults.headers.common[key] = value;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
    addDefaultHeader
};
