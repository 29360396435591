import { Row, Col } from "react-bootstrap";

const StepText = ({ text, className }) => {
  return (
    <>
      <Row className={`steptext__content ${className}`}>
        <Col>{text}</Col>
      </Row>
    </>
  );
};

export default StepText;
