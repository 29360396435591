const DotNav = (props) => {
  let indents = [];

  const generateDot = () => {
    for (var i = 1; i <= props.totalSteps; i++) {
      if (props.currentStep === i) {
        indents.push(
          <span key={i} className="dot active">
            •
          </span>
        );
      } else {
        indents.push(
          <span key={i} className="dot">
            •
          </span>
        );
      }
    }

    return indents;
  };

  generateDot();

  return <div className="nav__container">{indents}</div>;
};

export default DotNav;
