import React, { useState } from "react";
import { useParams } from "react-router-dom";
import StepWizard from "react-step-wizard";

import DotNav from "../../components/atomic/dotNav/DotNav";
import HearingTest from "../hearingTest/HearingTest";
import hearingtestList from "../../hearingtest.json";

const HearingTestStepWizard = (props) => {
  const { id } = useParams();

  const [initialStep] = useState(1);
  const [currentStep] = useState(1);

  const handleStepChange = (step) => {};

  return (
    <>
      <StepWizard
        isHashEnabled={true}
        nav={<DotNav />}
        className="mt-5"
        initialStep={initialStep}
        currentStep={currentStep}
        goToStep={currentStep}
        previousStep={currentStep - 1}
        onStepChange={handleStepChange}
      >
        {hearingtestList &&
          hearingtestList.map((value, idx) => {
            return (
              <HearingTest
                key={idx}
                hashKey={`/test/${id}/hearingtest/${value.hz}/ear/${value.ear}/`}
                data={value}
              />
            );
          })}
      </StepWizard>
    </>
  );
};

export default HearingTestStepWizard;
