import HTTPService from "./httpService";

const apiEndpoint = process.env.REACT_APP_HEARINGTEST_API_URL;

const startSession = async (data) => {
    return await HTTPService.post(apiEndpoint + "/sessions/start", data);
}

const createUser = async (data) => {
    return await HTTPService.post(apiEndpoint + "/users", data);
}

const createHearingTest = async (data) => {
    return await HTTPService.post(apiEndpoint + "/HearingTests", data);
}

const createPhoneticallyWord = async (data) => {
    return await HTTPService.post(apiEndpoint + "/phoneticallywords", data);
}

const calculateAverageVolume = async (data) => {
    return await HTTPService.get(apiEndpoint + "/calcs/averagevolume/" + data.session_id);
}

const getResult = async (data) => {
    return await HTTPService.get(apiEndpoint + "/result/" + data.session_id);
}

const generateReport = async (data) => {
    return await HTTPService.post(apiEndpoint + "/reports/generate/" + data.session_id);
}

export default {
    StartSession: startSession,
    CreateUser: createUser,
    CreateHearingTest: createHearingTest,
    CreatePhoneticallyWord: createPhoneticallyWord,
    CalculateAverageVolume: calculateAverageVolume,
    GetResult: getResult,
    GenerateReport: generateReport,
};
