import React from "react";
import { Route } from "react-router-dom";

import { Navbar, Container } from "react-bootstrap";

import { Logo, Header } from "../../components/hearingtest";

import logo from "../../assets/images/blue_logo.png";

const DefaultLayout = (props) => {
  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  const { component: Component, ...rest } = props;

  return (
    <>
      <Header />
      <Container fluid className="container p-0 w-80 mw-80">
        {/* <header className="header">
          <Navbar className="navbar-custom">
            <Navbar.Brand href="/">
              <Logo src={logo} />
            </Navbar.Brand>
          </Navbar>
        </header> */}
        <main className="main">
          <div className="content">
            {
              <Route
                {...rest}
                render={(matchProps) => <Component {...matchProps} />}
              />
            }
          </div>
        </main>
        <footer className="footer"></footer>
      </Container>
    </>
  );
};

export default DefaultLayout;
