import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

const PlayIcon = ({ show }) => {
  return (
    <div className={`${show ? `d-display` : `d-none`}`}>
      <FontAwesomeIcon icon={faPlayCircle} className="playCircleIcon" />
    </div>
  );
};

export default PlayIcon;
