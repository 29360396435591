import React, { useState, useEffect, useRef } from "react";

import { Row, Col } from "react-bootstrap";

import MaxVolumeModal from "../../components/maxVolumeModal/MaxVolumeModal";
import VolumeUpIcon from "../atomic/volumeUpIcon/VolumeUpIcon";
import PlayIcon from "../atomic/playIcon/PlayIcon";

import VolumeControl from "../volumeControl/VolumeControl";

import minusImage from "../../assets/images/minus.png";
import plusImage from "../../assets/images/plus.png";

const PlayAudio = ({
  audio = null,
  volume = 0.2,
  showVolume = true,
  showDecreaseVolumeButton = false,
  showIncreaseVolumeButton = false,
  showAudioControl = false,
  onAudioPlayed,
  onAudioPaused,
  onVolumeChanged,
  onVolumeDecreased,
  onVolumeIncreased,
  onAudioEnded,
  onDidntHearTone,
}) => {
  const audioPlayer = useRef(null);

  const [initialPath] = useState("/assets/audios/hearingTest/");
  const [initialVolume] = useState(0.2);
  const [currentVolume, setCurrentVolume] = useState(volume);
  const [showDecreaseVolume, setShowDecreaseVolume] = useState(
    showDecreaseVolumeButton
  );
  const [showIncreaseVolume, setShowIncreaseVolume] = useState(
    showIncreaseVolumeButton
  );
  const [showPlay, setShowPlay] = useState(true);
  const [showPause, setShowPause] = useState(false);
  const [showMaxVolumeModal, setShowMaxVolumeModal] = useState(false);

  const showOrHideVolume = () => {
    if (currentVolume === 0.2) {
      setShowIncreaseVolume(true);
      setShowDecreaseVolume(false);
    } else if (currentVolume === 0.8) {
      //setShowIncreaseVolume(false);
      setShowDecreaseVolume(true);
    } else {
      setShowIncreaseVolume(true);
      setShowDecreaseVolume(true);
    }
  };

  useEffect(() => {
    if (audioPlayer !== null) {
      showOrHideVolume();

      audioPlayer.current.volume = currentVolume;

      if (onVolumeChanged) {
        onVolumeChanged(currentVolume);
      }
    }
  }, [currentVolume]);

  const handlePlayAudio = (event) => {
    event.preventDefault();

    showOrHideVolume();

    setShowPause(true);
    setShowPlay(false);

    if (audioPlayer) {
      var playAudioPromise = audioPlayer.current.play();

      if (playAudioPromise !== undefined) {
        playAudioPromise
          .then(function () {
            setShowPause(true);
            setShowPlay(false);
          })
          .catch(function (error) {})
          .finally(() => {
            if (onAudioPlayed) {
              onAudioPlayed(event);
            }
          });
      }
    }
  };

  const handlePauseAudio = (event) => {
    event.preventDefault();

    showOrHideVolume();

    setShowPause(false);
    setShowPlay(true);

    if (audioPlayer != null) {
      audioPlayer.current.pause();
    }

    if (onAudioPaused) {
      onAudioPaused(event, currentVolume);
    }
  };

  const checkVolume = (vol) => {
    if (vol === 0.8 || vol >= 0.8) {
      return 0.8;
    } else if (vol === 0.2 || vol <= 0.2) {
      return 0.2;
    } else {
      return vol;
    }
  };

  const handleIncreaseVolume = (event) => {
    event.preventDefault();

    setShowPause(false);
    setShowPlay(true);

    var newVolume = checkVolume(currentVolume);
    newVolume = (newVolume * 10 + 0.1 * 10) / 10;

    setCurrentVolume(newVolume);

    if (newVolume > 0.8) {
      setShowMaxVolumeModal(true);
    }

    if (onVolumeIncreased) {
      onVolumeIncreased(event, newVolume);
    }
  };

  const handleDecreaseVolume = (event) => {
    event.preventDefault();

    setShowPause(false);
    setShowPlay(true);

    var newVolume = checkVolume(currentVolume);
    newVolume = (newVolume * 10 - 0.1 * 10) / 10;
    setCurrentVolume(newVolume);

    if (onVolumeDecreased) {
      onVolumeDecreased(event, newVolume);
    }
  };

  const handleDidntHearTone = (event) => {
    setShowMaxVolumeModal(false);
    onDidntHearTone(event);
  };

  const handleAudioEnded = (event) => {
    setShowPause(false);
    setShowPlay(true);

    if (onAudioEnded) {
      onAudioEnded(event);
    }
  };

  const handleTryAgain = (event) => {
    event.preventDefault();

    setCurrentVolume(volume);
    setShowMaxVolumeModal(false);

    var newVolume = (volume * 10) / 10;

    if (onVolumeDecreased) {
      onVolumeDecreased(event, newVolume);
    }
  };

  return (
    <>
      <div className="playAudio">
        <div className="content">
          <Row className="m-0 mb-3">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="vertical-align-middle align-center audioBox"
            >
              <audio
                ref={audioPlayer}
                src={`${initialPath}${audio}`}
                volume={1.0}
                onEnded={handleAudioEnded}
                id="audioElement"
                crossOrigin="anonymous"
              />

              <div className="blueBox">
                <a onClick={handlePlayAudio}>
                  <PlayIcon show={showPlay} />
                </a>

                <a onClick={handlePauseAudio}>
                  <VolumeUpIcon show={showPause} />
                </a>
              </div>
            </Col>
          </Row>

          <Row
            className="m-0"
            className={showAudioControl ? `d-display` : `d-none`}
          >
            <Col
              xs={2}
              sm={2}
              md={2}
              lg={5}
              xl={4}
              className="vertical-align-middle text-right px-0 w-unset mw-unset"
            >
              <button
                onClick={handleDecreaseVolume}
                className={`volume decrease w-unset mw-unset ${
                  showDecreaseVolume && showVolume ? `d-display` : `d-none`
                }`}
              >
                <img src={minusImage} />
              </button>
            </Col>

            <Col
              xs={8}
              sm={8}
              md={8}
              lg={2}
              xl={4}
              className="vertical-align-middle"
            >
              <VolumeControl min={0.2} max={0.8} value={currentVolume} />
            </Col>

            <Col
              xs={2}
              sm={2}
              md={2}
              lg={5}
              xl={4}
              className="vertical-align-middle text-left px-0"
            >
              <button
                onClick={handleIncreaseVolume}
                className={`volume increase w-unset mw-unset ${
                  showIncreaseVolume && showVolume ? `d-display` : `d-none`
                }`}
              >
                <img src={plusImage} />
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <MaxVolumeModal
        show={showMaxVolumeModal}
        onDidntHearTone={handleDidntHearTone}
        onTryAgain={handleTryAgain}
      />
    </>
  );
};

export default PlayAudio;
