import 'react-app-polyfill/ie9'; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./i18n";

import GA4React from "ga-4-react";

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_G4_ID);

(async _ => {
  await ga4react.initialize().then((ga4) => {
    ga4.pageview('path')
    ga4.gtag('event', 'pageview', 'path') // or your custom gtag event
  }, (err) => {
    console.error(err)
  });

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
