import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import './scss/style.scss';

import './App.css';

import Home from './views/home/Home';
import Instructions from './views/instructions/Instructions';
import VolumeSetting from './views/volumeSetting/VolumeSetting';
import HearingTestStepWizard from './views/hearingTestStepWizard/HearingTestStepWizard';
import PhoneticallyWordsStepWizard from './views/phoneticallyWordsStepWizard/PhoneticallyWordsStepWizard';
import ContactForm from "./views/contactForm/ContactForm";
import Report from "./views/report/Report";
import Result from "./views/result/Result";
import ThankYou from "./views/thankYou/ThankYou";

import NotFound from "./views/pages/notFound/NotFound";
import { createBrowserHistory } from 'history';
import DefaultLayout from './containers/HearingTest/DefaultLayout';

import GA4React from "ga-4-react";

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_G4_ID);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  (async _ => {
    ga4react.pageview(location.hash)
    ga4react.gtag('event', 'pageview', location.hash) // or your custom gtag event
  })();
});

function App() {
  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <Router history={history}>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            path="/"
            name="Home"
            component={Home}
            render={props => <Home {...props} />}
            exact
          />

          <Route
            path="/result/:id"
            name="Result"
            component={Result}
            render={(props) => <Result {...props} />}
            exact={true}
          />

          <DefaultLayout>
            <Switch>
              <Route
                path="/instructions"
                name="Instructions"
                component={Instructions}
                render={props => <Instructions {...props} />}
                exact
              />
              <Route
                path="/volumesetting"
                name="Volume Setting"
                component={VolumeSetting}
                render={props => <VolumeSetting {...props} />}
                exact
              />

              <Route
                path="/test/:id/hearingtest/:hz/ear/:ear/"
                name="HearingTest StepWizard"
                component={HearingTestStepWizard}
                render={props => <HearingTestStepWizard {...props} />}
                exact
              />

              <Route
                path="/test/:id/words/"
                name="PhoneticallyWords StepWizard"
                component={PhoneticallyWordsStepWizard}
                render={props => <PhoneticallyWordsStepWizard {...props} />}
                exact
              />

              <Route
                path="/test/:id/words/:hz/word/:word/"
                name="PhoneticallyWords StepWizard"
                component={PhoneticallyWordsStepWizard}
                render={props => <PhoneticallyWordsStepWizard {...props} />}
                exact
              />

              <Route
                path="/contact/:id"
                name="Contact"
                component={ContactForm}
                render={props => <ContactForm {...props} />}
                exact={true}
              />

              <Route
                path="/report/:id"
                name="Report"
                component={Report}
                render={(props) => <Report {...props} />}
                exact={true}
              />

              <Route
                path="/thankyou"
                name="Thank You"
                component={ThankYou}
                render={(props) => <ThankYou {...props} />}
                exact={true}
              />
            </Switch>
          </DefaultLayout>

          <Route path='/404' component={NotFound} />

          <Redirect to='/' />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;