import defaultLogo from "../../../assets/images/blue_logo.png";

const Logo = ({ src = defaultLogo }) => {
  return (
    <>
      <img src={src} className="logo" alt="ID hearing test logo" />
    </>
  );
};

export default Logo;
