import React, { useState, useRef } from "react";

import { Row, Col } from "react-bootstrap";

import VolumeUpIcon from "../atomic/volumeUpIcon/VolumeUpIcon";
import PlayIcon from "../atomic/playIcon/PlayIcon";

const ListenAudio = ({
  audio = null,
  volume = 0.5,
  onAudioPlayed,
  onAudioPaused,
  onAudioEnded,
}) => {
  const audioPlayer = useRef(null);

  const [initialPath] = useState("../../assets");
  const [currentAudio] = useState(audio);
  const [currentVolume] = useState(volume);
  const [showPlay, setShowPlay] = useState(true);
  const [showPause, setShowPause] = useState(false);

  const handlePlayAudio = (event) => {
    event.preventDefault();

    if (currentAudio !== null) {
      setShowPause(true);
      setShowPlay(false);

      var playAudioPromise = audioPlayer.current.play();

      if (playAudioPromise !== undefined) {
        playAudioPromise
          .then(function () {
            setShowPause(true);
            setShowPlay(false);
          })
          .catch(function (error) {})
          .finally(() => {
            if (onAudioPlayed) {
              onAudioPlayed(event);
            }
          });
      }
    }
  };

  const handlePauseAudio = (event) => {
    event.preventDefault();

    setShowPause(false);
    setShowPlay(true);

    if (currentAudio != null) {
      audioPlayer.current.pause();
    }

    if (onAudioPaused) {
      onAudioPaused(event);
    }
  };

  const handleAudioEnded = (event) => {
    setShowPause(false);
    setShowPlay(true);
    if (onAudioEnded) {
      onAudioEnded(event);
    }
  };

  return (
    <>
      <div className="playAudio">
        <div className="content">
          <Row className="m-0">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="vertical-align-middle align-center audioBox"
            >
              <audio
                ref={audioPlayer}
                src={`${initialPath}${currentAudio}`}
                volume={currentVolume > 1 ? currentVolume / 100 : currentVolume}
                onEnded={handleAudioEnded}
                id="audioElement"
                crossOrigin="anonymous"
              />

              <div className="blueBox">
                <a onClick={handlePlayAudio}>
                  <PlayIcon show={showPlay} />
                </a>

                <a onClick={handlePauseAudio}>
                  <VolumeUpIcon show={showPause} />
                </a>
              </div>

              {/* <a href="#" onClick={handlePlayAudio}>
                <img
                  className={`playImage ${showPlay ? `d-display` : `d-none`}`}
                  src={playImage}
                  alt="Play"
                />
              </a>

              <a href="#" onClick={handlePauseAudio}>
                <img
                  className={`pauseImage ${showPause ? `d-display` : `d-none`}`}
                  src={pauseImage}
                  alt="Pause"
                />
              </a> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ListenAudio;
