import React, { useState, useEffect } from "react";

import { ProgressBar } from "react-bootstrap";

const ProgressBarNav = (props) => {
  const [currentPercent, setCurrentPercent] = useState(0);

  useEffect(() => {
    if (props.currentStep === 1) {
      setCurrentPercent(0);
    } else {
      const p = (props.currentStep / props.totalSteps) * 100;
      setCurrentPercent(Math.round(p));
    }
  }, [props]);

  return (
    <div className="nav__container">
      <ProgressBar now={currentPercent} label={`${currentPercent}%`} />
    </div>
  );
};

export default ProgressBarNav;
