import "./style.css";

const Label = ({ id, name, text, className = "" }) => {
  return (
    <>
      <label id={id} name={name} className={`fieldLabel ${className}`}>
        {text}
      </label>
    </>
  );
};

export default Label;
