import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, FormGroup } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

import { ActionButton } from "../../components/atomic";
import StepText from "../../components/stepText/StepText";
import ListenAudio from "../../components/listenAudio/ListenAudio";
import { InputText, Label } from "../../components/atomic";

import HearingTestService from "../../services/hearingTestService";

const Words = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id: sessionId } = useParams();

  const [enableButtonNext, setEnableButtonNext] = useState(false);
  const [showConfirmSkipWord, setConfirmSkipWord] = useState(false);
  const [showSkipWord, setShowSkipWord] = useState(false);
  const [typedWord, setTypedWord] = useState("");

  const btnNextStepRef = useRef(null);
  const inputWordRef = useRef(null);

  const goToNextPage = () => {
    const data = {
      session_id: sessionId,
      db: props.volume,
      earSide: props.ear,
      word: props.word,
      typedWord: typedWord,
    };

    HearingTestService.CreatePhoneticallyWord(data)
      .then((response) => {
        if (props.currentStep === props.totalSteps) {
          history.push(`/contact/${sessionId}/`);
        } else {
          props.nextStep();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const handleNextStep = (event) => {
    event.preventDefault();
    goToNextPage();
  };

  const handleConfirmSkipWord = (event) => {
    event.preventDefault();
    goToNextPage();
  };

  const handleSkipWord = (event) => {
    event.preventDefault();
    setConfirmSkipWord(true);
    setShowSkipWord(false);
  };

  const handleAudioEnded = (event) => {
    setShowSkipWord(true);
    setEnableButtonNext(true);
    inputWordRef.current.focus();
  };

  const handleChange = (event) => {
    setTypedWord(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      btnNextStepRef.current.click(e);
    }
  };

  return (
    <>
      <div className="phoneticallyWord__container">
        <Row className="mb-xs-1 mb-sm-1 mb-md-2 mb-lg-2 mb-xl-3">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <StepText
              text={ReactHtmlParser(
                t("Press the play button to start the tone")
              )}
              className="words__title"
            />
          </Col>
        </Row>

        <Row className="mb-xs-1 mb-sm-1 mb-md-2 mb-lg-2 mb-xl-3">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            {props.src && (
              <ListenAudio
                audio={props.src}
                volume={props.volume}
                onAudioEnded={handleAudioEnded}
              />
            )}
          </Col>
        </Row>

        <Row className="mt-2">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            <FormGroup>
              <div className="w-100 text-left ml-3">
                <Label
                  text={ReactHtmlParser(t("Type what you hear below")) + ":"}
                  className="text-left"
                />
              </div>
              <InputText
                ref={inputWordRef}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row
          className={`mb-xs-1 mb-sm-1 mb-md-2 mb-lg-2 mb-xl-3 ${
            showSkipWord ? `d-display` : `d-none`
          }`}
        >
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="vertical-align-middle text-center"
          >
            <ActionButton
              onClick={handleSkipWord}
              text={ReactHtmlParser(t("Skip"))}
              className="btn-skipword"
            />
          </Col>
        </Row>

        <Row
          className={`mb-xs-1 mb-sm-1 mb-md-2 mb-lg-2 mb-xl-3 ${
            showConfirmSkipWord ? `d-display` : `d-none`
          }`}
        >
          <Col
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className="vertical-align-middle text-center"
          >
            <span className="skip-word">{ReactHtmlParser(t("SKIP_WORD"))}</span>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="btn-skip__container"
          >
            <ActionButton
              onClick={handleConfirmSkipWord}
              text={ReactHtmlParser(t("Yes skip"))}
              className="btn-skipword"
            />
          </Col>
        </Row>

        <Row className="mt-xs-1 mt-sm-1 mt-md-3 mt-lg-2 mt-xl-3 btn-next__container">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            <ActionButton
              ref={btnNextStepRef}
              onClick={handleNextStep}
              text={ReactHtmlParser(t("Press enter"))}
              disable={!enableButtonNext}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Words;
