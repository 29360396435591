import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

import { NextButton, BackButton } from "../../components/atomic";
import StepText from "../../components/stepText/StepText";
import PlayAudio from "../../components/playAudio/PlayAudio";
import HearingTestService from "../../services/hearingTestService";
import googleAnalyticsG4Helpers from "../../helpers/googleAnalyticsG4Helpers";

const HearingTest = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id: sessionId } = useParams();

  const [title, setTitle] = useState(
    t("Press the play button to start the tone")
  );
  const [subTitle, setSubtitle] = useState("");
  const [data] = useState(props.data);
  const [currentAudio, setCurrentAudio] = useState("");
  const [enableButtonNext, setEnableButtonNext] = useState(false);
  const [enableButtonBack, setEnableButtonBack] = useState(true);
  const [currentVolume, setCurrentVolume] = useState(0.2);

  useEffect(() => {
    setAudio(currentVolume);
  }, []);

  useEffect(() => {
    if (props.currentStep === 1) {
      //setEnableButtonBack(false);
    } else {
      setEnableButtonBack(true);
    }
  }, [props.currentStep]);

  const goToNextPage = () => {
    let data = {
      session_id: sessionId,
    };

    HearingTestService.CalculateAverageVolume(data)
      .then((response) => {
        history.push(
          `/test/${sessionId}/words/${response.data.data.volume}/word/1/`
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const saveHearingTest = () => {
    const request = {
      session_id: sessionId,
      db: currentVolume * 100,
      hz: data.hz,
      earSide: data.ear,
    };

    HearingTestService.CreateHearingTest(request)
      .then((response) => {
        if (props.currentStep === props.totalSteps) {
          goToNextPage();
        } else {
          props.goToStep(props.currentStep + 1);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const handleNextStep = (event) => {
    event.preventDefault();
    saveHearingTest();
  };

  const handlePreviousStep = (event) => {
    event.preventDefault();

    if (props.currentStep === 1) {
      history.push(`/volumeSetting`);
      //setEnableButtonNext(false);
    } else {
      props.previousStep();
    }
  };

  const setAudio = (volume) => {
    if (data) {
      var selectedAudio = data.volumes.filter((item) => {
        var v = volume * 100;
        if (item.volume === v) {
          return item;
        }
      });
      if (selectedAudio && selectedAudio.length > 0) {
        setCurrentAudio(selectedAudio[0].audio);
      }
    }
  };

  const handleDecreaseVolume = (event, value) => {
    event.preventDefault();
    setCurrentVolume(value);
    setAudio(value);
  };

  const handleIncreaseVolume = (event, value) => {
    event.preventDefault();
    setCurrentVolume(value);
    setAudio(value);
  };

  const handleVolumeChanged = (value) => {
    //setCurrentVolume(value);
  };

  const handleAudioPaused = (event) => {
    event.preventDefault();
    setTitle("Press the play button to start the tone");
    setEnableButtonNext(true);
  };

  const handleAudioPlayed = (event) => {
    event.preventDefault();
    googleAnalyticsG4Helpers.event("audio_play", "Play audio");

    setEnableButtonNext(false);

    setTitle(ReactHtmlParser(t("Using Volumes")));

    if (data) {
      setSubtitle(ReactHtmlParser(t("ear Hz", { ear: data.ear, hz: data.hz })));
    }
  };

  const handleAudioEnded = (event) => {
    event.preventDefault();

    setEnableButtonNext(true);
  };

  const handleDidntHearTone = (event) => {
    event.preventDefault();
    saveHearingTest();
  };

  return (
    <>
      <div className="hearingTest_container">
        <Row className="mb-xs-1 mb-sm-1 mb-md-2 mb-lg-2 mb-xl-3">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <StepText text={title} className="title" />
          </Col>
        </Row>
        <Row className="mb-xs-1 mb-sm-1 mb-md-2 mb-lg-2 mb-xl-3">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <StepText text={subTitle} className="subTitle" />
          </Col>
        </Row>
        <Row className="mb-xs-3 mb-sm-3 mb-md-5 mb-lg-5 mb-xl-5">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            <PlayAudio
              audio={currentAudio}
              showAudioControl={true}
              onAudioPlayed={handleAudioPlayed}
              onAudioPaused={handleAudioPaused}
              onAudioEnded={handleAudioEnded}
              onVolumeChanged={handleVolumeChanged}
              onVolumeDecreased={handleDecreaseVolume}
              onVolumeIncreased={handleIncreaseVolume}
              onDidntHearTone={handleDidntHearTone}
            />
          </Col>
        </Row>

        <Row className="mb-xs-3 mb-sm-3 mb-md-5 mb-lg-5 mb-xl-5 btn-next__container">
          <Col xs={3} sm={12} md={4} lg={4} xl={4} className="text-right">
            <BackButton onClick={handlePreviousStep} />
          </Col>

          <Col xs={6} sm={12} md={8} lg={8} xl={8} className="text-left">
            <NextButton
              onClick={handleNextStep}
              text={ReactHtmlParser(t("Continue"))}
              disable={!enableButtonNext}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HearingTest;
