import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

import { NextButton, BackButton } from "../../components/atomic";

//import { IDHearingTestCalibration from "%PUBLIC_URL%/assets/audios/IDHearingTestCalibration.mp3";

import PlayAudio from "../../components/playAudio/PlayAudio";

import TextDescription from "../../components/textdescription/TextDescription";
import StepTitle from "../../components/steptitle/StepTitle";
import StepText from "../../components/stepText/StepText";
import HearingTestService from "../../services/hearingTestService";
import Disclaimer from "../../components/disclaimer/Disclaimer";
import googleAnalyticsG4Helpers from "../../helpers/googleAnalyticsG4Helpers";

const VolumeSetting = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [enableButtonNext, setEnableButtonNext] = useState(false);

  const handleNextStep = (event) => {
    event.preventDefault();

    const data = {};

    HearingTestService.StartSession(data)
      .then((response) => {
        googleAnalyticsG4Helpers.event("start_session", "Start a new session");

        const session_id = response.data.data.session_id;
        history.push(`/test/${session_id}/hearingtest/500/ear/right/`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const handlePlay = (event) => {
    event.preventDefault();
  };

  const handleIncreaseVolume = (event) => {
    event.preventDefault();
  };

  const handleDecreaseVolume = (event) => {
    event.preventDefault();
  };

  const handlePreviousStep = (event) => {
    event.preventDefault();
    history.push(`/instructions`);
  };

  const handleAudioPlayed = (event) => {
    event.preventDefault();

    setEnableButtonNext(true);
  };

  const handleAudioPaused = (event) => {
    event.preventDefault();
  };

  const handleAudioEnded = (event) => {
    event.preventDefault();

    setEnableButtonNext(true);
  };

  return (
    <>
      <div className="instructions__container mt-5">
        <Row className="mb-1">
          <Col md={12}>
            <StepTitle text={ReactHtmlParser(t("VOLUMESETTING_Title"))} />
          </Col>
        </Row>
        <Row className="mb-sm-3 mb-5">
          <Col md={12}>
            <TextDescription
              text={ReactHtmlParser(t("VOLUMESETTING_Description"))}
            />
          </Col>
        </Row>
        <Row className="mb-sm-3 mb-5">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            <PlayAudio
              audio={"IDVolumeCalibration.wav"}
              volume={1.0}
              onAudioPlayed={handleAudioPlayed}
              onAudioPaused={handleAudioPaused}
              onAudioEnded={handleAudioEnded}
              showVolume={false}
              showDecreaseVolume={false}
              showIncreaseVolume={false}
            />
          </Col>
        </Row>

        <Row className="mb-sm-3 mt-5">
          <Col xs={3} sm={12} md={4} lg={4} xl={4} className="text-right">
            <BackButton onClick={handlePreviousStep} />
          </Col>

          <Col xs={6} sm={12} md={8} lg={8} xl={8} className="text-left">
            <NextButton
              onClick={handleNextStep}
              text={ReactHtmlParser(t("Continue"))}
              disable={!enableButtonNext}
            />
          </Col>
        </Row>
        <Row className="mb-sm-3 mt-5 text-center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Disclaimer
              text={ReactHtmlParser(
                t("VOLUMESETTING_TermsConditionsDisclaimer", {
                  link: "/assets/docs/Terms_and_Conditions_and_Disclaimer.pdf",
                })
              )}
              className="disclaimer__volumeSetting"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VolumeSetting;
