import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Joi from "joi-browser";

import { Row, Col, FormGroup } from "react-bootstrap";

import ReactHtmlParser from "react-html-parser";
import StepText from "../../components/stepText/StepText";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import { NextButton, BackButton } from "../../components/atomic";
import { InputText, Label, ErrorMessage } from "../../components/atomic";

import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

import HearingTestService from "../../services/hearingTestService";

import TermsConditions from "../../assets/docs/Terms_and_Conditions_and_Disclaimer.pdf";
import googleAnalyticsG4Helpers from "../../helpers/googleAnalyticsG4Helpers";
import useGetUserLocationDetails from "../../hooks/useGetUserLocationDetails";

const ContactForm = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [data, setData] = useState({
    session_id: null,
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    age: null,
    gender: "",
    state: "",
    terms: false,
  });

  const [errors, setErrors] = useState({});
  const [enableButtonNext, setEnableButtonNext] = useState(true);
  const [enableButtonBack] = useState(true);
  const { loading, location, error } = useGetUserLocationDetails();

  const [genders] = useState([
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ]);

  const australiaStates = [
    { value: "ACT", label: "ACT" },
    { value: "New South Wales", label: "New South Wales" },
    { value: "Queensland", label: "Queensland" },
    { value: "South Australia", label: "South Australia" },
    { value: "Tasmania", label: "Tasmania" },
    { value: "Victoria", label: "Victoria" },
    { value: "Western Australia", label: "Western Australia" },
  ];
  const usStates = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];

  const [schema] = useState({
    session_id: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    firstname: Joi.string()
      .required()
      .label("First Name")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    lastname: Joi.string()
      .required()
      .label("Last Name")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    email: Joi.string()
      .email()
      .required()
      .label("Email")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    phoneNumber: Joi.string()
      .required()
      .label("Phone number")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    age: Joi.number()
      .integer()
      .required()
      .min(0)
      .max(120)
      .error((errors) => {
        return errors.map((error) => {
          if (error.type === "number.max") {
            return { message: "Age can not be negative or more than 120" };
          } else if (error.type === "number.min") {
            return { message: "Age can not be negative or more than 120" };
          }
          return { message: "Required" };
        });
      }),
    gender: Joi.any()
      .valid(["Male", "Female"])
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    state: Joi.any()
      .valid([
        "ACT",
        "New South Wales",
        "Queensland",
        "South Australia",
        "Tasmania",
        "Victoria",
        "Western Australia",
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ])
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
    terms: Joi.any()
      .valid([true])
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Required" };
        });
      }),
  });

  const validate = () => {
    const options = {
      abortEarly: false,
    };

    let JoiSchema = Joi.object().keys(schema);
    const { error } = Joi.validate(data, JoiSchema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      if (!errors[item.path[0]]) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = (name, value) => {
    const obj = { [name]: value };
    const newSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, newSchema);
    return error ? error.details[0].message : null;
  };

  const handleChanged = (name, value) => {
    setData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    setData((prevValues) => ({
      ...prevValues,
      ["session_id"]: id,
    }));
  }, [id]);

  const renderStatesControl = () => {
    if (location && location.country) {
      if (location.country.includes("AU")) {
        return (
          <Select
            id="state"
            name="state"
            required
            selectValue={data.state}
            options={australiaStates}
            styles={selectStyles}
            onChange={handleSelectChange}
            isSearchable={false}
          />
        );
      } else {
        if (location.country.includes("US")) {
          return (
            <Select
              id="state"
              name="state"
              required
              selectValue={data.state}
              options={usStates}
              styles={selectStyles}
              onChange={handleSelectChange}
              isSearchable={false}
            />
          );
        }
      }
    } else {
      return (
        <Select
          id="state"
          name="state"
          required
          selectValue={data.state}
          options={australiaStates}
          styles={selectStyles}
          onChange={handleSelectChange}
          isSearchable={false}
        />
      );
    }
  };

  const handlePreviousStep = (event) => {
    event.preventDefault();
  };

  const handleSelectChange = (selectedOption, element) => {
    const errorMessage = validateProperty(element.name, selectedOption.value);

    if (errorMessage != null) {
      errors[element.name] = errorMessage;
    } else {
      delete errors[element.name];
      setErrors(errors);
    }

    handleChanged(element.name, selectedOption.value);
  };

  const handleCheckBoxChange = (event) => {
    const errorMessage = validateProperty(
      event.target.name,
      !event.target.value
    );

    if (errorMessage != null) {
      errors[event.target.name] = errorMessage;
    } else {
      delete errors[event.target.name];
      setErrors(errors);
    }

    handleChanged(event.target.name, event.target.checked);
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "28px",
      backgroundColor: "white",
      height: "60px",
      ":hover": {
        ...styles[":hover"],
        border: "1px solid #3756e9;",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        textAlign: "left",
        backgroundColor: "#FFF",
        borderRadius: "0 28px",
        color: "#000",
        ":active": {
          ...styles[":active"],
          backgroundColor: "#FFF",
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  const handleInputChanged = (event) => {
    const errorMessage = validateProperty(
      event.target.name,
      event.target.value
    );

    if (errorMessage != null) {
      errors[event.target.name] = errorMessage;
    } else {
      delete errors[event.target.name];
      setErrors(errors);
    }

    handleChanged(event.target.name, event.target.value);
  };

  const handleSubmit = (event) => {
    try {
      const errorResult = validate();

      if (errorResult) {
        setErrors(errorResult);
        return;
      } else {
        setErrors({});
      }

      HearingTestService.CreateUser(data)
        .then((response) => {
          HearingTestService.GenerateReport(data)
            .then((response) => {
              googleAnalyticsG4Helpers.event("end_test", "End Test");
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              history.push(`/thankyou`);
            });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="contact__container">
        <Row className="mb-1">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <StepText
              text={ReactHtmlParser(t("CONTACTFORM_TITLE"))}
              className="contactform__title"
            />
          </Col>
        </Row>
        <Row className="mb-1">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <StepText
              text={ReactHtmlParser(t("CONTACTFORM_SUBTITLE"))}
              className="contactform__subtitle"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            <FormGroup>
              <div className="w-100 text-left ml-3">
                <Label
                  text={ReactHtmlParser(t("First Name"))}
                  className="text-left"
                />
              </div>
              <InputText
                id="firstname"
                name="firstname"
                value={data.firstname}
                required
                placeholder={ReactHtmlParser(t("First Name"))}
                onChange={handleInputChanged}
              />
              <ErrorMessage message={errors.firstname} />
            </FormGroup>
            <FormGroup>
              <div className="w-100 text-left ml-3">
                <Label
                  text={ReactHtmlParser(t("Last Name"))}
                  className="text-left"
                />
              </div>
              <InputText
                id="lastname"
                name="lastname"
                value={data.lastname}
                required
                placeholder={ReactHtmlParser(t("Last Name"))}
                onChange={handleInputChanged}
              />
              <ErrorMessage message={errors.lastname} />
            </FormGroup>
            <FormGroup>
              <div className="w-100 text-left ml-3">
                <Label
                  text={ReactHtmlParser(t("E-mail address"))}
                  className="text-left"
                />
              </div>
              <InputText
                id="email"
                name="email"
                type="email"
                required
                value={data.email}
                placeholder={ReactHtmlParser(t("E-mail address"))}
                onChange={handleInputChanged}
              />
              <ErrorMessage message={errors.email} />
            </FormGroup>
            <FormGroup>
              <div className="w-100 text-left ml-3">
                <Label
                  text={ReactHtmlParser(t("Phone number"))}
                  className="text-left"
                />
              </div>
              <InputText
                id="phoneNumber"
                name="phoneNumber"
                required
                value={data.phoneNumber}
                placeholder={ReactHtmlParser(t("Phone number"))}
                onChange={handleInputChanged}
              />
              <ErrorMessage message={errors.phoneNumber} />
            </FormGroup>
            <FormGroup>
              <div className="w-100 text-left ml-3">
                <Label text={ReactHtmlParser(t("Age"))} className="text-left" />
              </div>
              <InputText
                id="age"
                name="age"
                type="numeric"
                value={data.age}
                required
                placeholder={ReactHtmlParser(t("Age"))}
                onChange={handleInputChanged}
              />
              <ErrorMessage message={errors.age} />
            </FormGroup>
            <FormGroup>
              <div className="w-100 text-left ml-3">
                <Label
                  text={ReactHtmlParser(t("Gender"))}
                  className="text-left"
                />
              </div>
              <Select
                id="gender"
                name="gender"
                required
                selectValue={data.gender}
                options={genders}
                styles={selectStyles}
                onChange={handleSelectChange}
                isSearchable={false}
              />
              <ErrorMessage message={errors.gender} />
            </FormGroup>
            <FormGroup>
              <div className="w-100 text-left ml-3">
                <Label
                  text={ReactHtmlParser(t("State"))}
                  className="text-left"
                />
              </div>
              {renderStatesControl()}

              <ErrorMessage message={errors.state} />
            </FormGroup>
            <FormGroup className="w-100 text-left ml-3">
              <label>
                <Checkbox
                  id="terms"
                  name="terms"
                  value={data.terms}
                  onChange={handleCheckBoxChange}
                />
                <span>
                  &nbsp; I have read and agreed to the{" "}
                  <a href={TermsConditions} target="_blank" rel="noreferrer">
                    terms
                  </a>{" "}
                  & conditions and{" "}
                  <a href={TermsConditions} target="_blank" rel="noreferrer">
                    privacy policy
                  </a>
                  .
                </span>
              </label>
              <ErrorMessage message={errors.terms} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mb-5 text-center"
          >
            {/* <BackButton
              onClick={handlePreviousStep}
              disable={!enableButtonBack}
            /> */}
            <NextButton
              type="submit"
              onClick={handleSubmit}
              text={ReactHtmlParser(t("Get Results"))}
              disable={!enableButtonNext}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactForm;
