import { useState, useEffect } from "react";
import IPGeoLocationService from "../services/ipGeoLocationService";
import { v4 } from "public-ip";

const useGetUserLocationDetails = () => {
    const [state, setState] = useState({
        loading: true,
        location: undefined,
        error: undefined,
    });

    useEffect(async () => {
        v4().then((res) => {
            IPGeoLocationService.getLocationDetailsByIPAddress(res)
                .then(response => setState({ loading: false, location: response.data }))
                .catch(error => setState({ loading: false, error }));
        })

    }, []);

    return state;
};

export default useGetUserLocationDetails;