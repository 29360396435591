import GA4React from "ga-4-react";

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_G4_ID);

const event = (name, value) => {
    ga4react.gtag('event', name, value);
}

export default {
    event
}