import { Button } from "react-bootstrap";

const NextButton = ({
  id,
  name,
  text,
  type = "button",
  onClick,
  className = "",
  disable = false,
}) => {
  return (
    <>
      <Button
        id={id}
        name={name}
        type={type}
        onClick={onClick}
        className={`btnNext ${className}`}
        disabled={disable ? "disabled" : ""}
      >
        {text}
      </Button>
    </>
  );
};

export default NextButton;
