import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import HearingTestService from "../../services/hearingTestService";

import logo from "../../assets/images/blue_logo.png";

const Result = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const leftEarAudiogram = useRef(null);
  const rightEarAudiogram = useRef(null);

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    heaging_loss: 0,
    phonetically_words: {
      left: {
        correct: 0,
        total: 0,
      },
      right: {
        correct: 0,
        total: 0,
      },
    },
    hearing_test: {
      left: [],
      right: [],
    },
  });

  const [dataLeftEar, setDataLeftEar] = useState({
    labels: ["500hz", "1000hz", "2000hz", "3000hz", "4000hz", "6000hz"],
    datasets: [
      {
        backgroundColor: "#000099",
        borderColor: "#000099",
        pointBorderColor: "#FF0000",
        pointBackgroundColor: "#FF0000",
        data: [],
        lineTension: 0,
        pointRadius: 5,
        pointHoverRadius: 10,
        pointHitRadius: 30,
        pointBorderWidth: 2,
        fill: false,
      },
    ],
  });

  const [dataRightEar, setDataRightEar] = useState({
    labels: ["500hz", "1000hz", "2000hz", "3000hz", "4000hz", "6000hz"],
    datasets: [
      {
        backgroundColor: "#000099",
        borderColor: "#000099",
        pointBorderColor: "#FF0000",
        pointBackgroundColor: "#FF0000",
        data: [],
        lineTension: 0,
        pointRadius: 5,
        pointHoverRadius: 10,
        pointHitRadius: 30,
        pointBorderWidth: 2,
        fill: false,
      },
    ],
  });

  const optionsLeftEar = React.useMemo(
    () => ({
      responsive: true,
      title: {
        display: true,
        text: "Left ear audiogram",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
      showLines: true,
      animation: {
        duration: 0, // general animation time
      },
      legend: {
        display: false,
        position: "top",
        labels: {
          boxWidth: 80,
          // This more specific font property overrides the global property
          fontColor: "black",
          fontSize: 20,
        },
      },
      responsiveAnimationDuration: 0,
      scales: {
        xAxes: [
          {
            display: true,
            showLine: true,
            scaleLabel: {
              display: true,
              labelString: "Hz",
            },
            ticks: {
              max: 6000,
              min: 500,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            showLine: true,
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: "db",
            },
            ticks: {
              max: 100,
              min: 0,
              stepSize: 10,
              reverse: true,
            },
          },
        ],
      },
    }),
    []
  );

  const optionsRightEar = React.useMemo(
    () => ({
      responsive: true,
      title: {
        display: true,
        text: "Right ear audiogram",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
      showLines: true,
      animation: {
        duration: 0, // general animation time
      },
      legend: {
        display: false,
        position: "top",
        labels: {
          boxWidth: 80,
          // This more specific font property overrides the global property
          fontColor: "black",
          fontSize: 20,
        },
      },
      responsiveAnimationDuration: 0,
      scales: {
        xAxes: [
          {
            display: true,
            showLine: true,
            scaleLabel: {
              display: true,
              labelString: "Hz",
            },
            ticks: {
              max: 6000,
              min: 500,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            showLine: true,
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: "db",
            },
            ticks: {
              max: 100,
              min: 0,
              stepSize: 10,
              reverse: true,
            },
          },
        ],
      },
    }),
    []
  );

  const axes = React.useMemo(
    () => [
      {
        beginAtZero: true,
        suggestedMax: 80,
        primary: true,
        position: "bottom",
        show: false,
      },
      {
        type: "linear",
        position: "left",
        show: true,
        data: "hz",
      },
    ],
    []
  );

  const series = React.useMemo(
    () => ({
      showPoints: true,
    }),
    []
  );

  useEffect(() => {
    const data = {
      session_id: id,
    };

    HearingTestService.GetResult(data).then((response) => {
      setData(response.data.data);
      if (response.data.data.hearing_test) {
        if (response.data.data.hearing_test.left) {
          let leftData = [];
          response.data.data.hearing_test.left.forEach((element) => {
            leftData.push(element.db);
          });
          dataLeftEar.datasets[0].data = leftData;
          setDataLeftEar(dataLeftEar);
          leftEarAudiogram.current.chartInstance.update();
        }
        if (response.data.data.hearing_test.right) {
          let rightData = [];
          response.data.data.hearing_test.right.forEach((element) => {
            rightData.push(element.db);
          });
          dataRightEar.datasets[0].data = rightData;
          setDataRightEar(dataRightEar);
          rightEarAudiogram.current.chartInstance.update();
        }
      }
    });
  }, [dataLeftEar, dataRightEar, id]);

  return (
    <Container className="result_container">
      <header className="header">
        <div className="logo__container">
          <img
            src={logo}
            className="w-100 d-inline-block align-top logo"
            alt="ID hearing test logo"
          />
        </div>
      </header>
      <main className="main">
        <div className="content mt-5">
          <Row className="user-details__container">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pl-0">
              <span>
                {ReactHtmlParser(
                  t("Hearing Test Results for", {
                    firstname: data.firstname,
                    lastname: data.lastname,
                  })
                )}
              </span>
            </Col>
          </Row>

          {/* Hearing Lost */}
          <Row className="hearing-loss__container">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {ReactHtmlParser(
                t("Hearing Loss Result", { value: data.heaging_loss })
              )}
            </Col>
          </Row>

          {/* <Row className="phonetically-words__container">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span>{ReactHtmlParser(t("Phonetically Words"))}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span>
                    {ReactHtmlParser(
                      t("Left Ear Result", {
                        correct: data.phonetically_words.left.correct,
                        total: data.phonetically_words.left.total,
                      })
                    )}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span>
                    {ReactHtmlParser(
                      t("Right Ear Result", {
                        correct: data.phonetically_words.right.correct,
                        total: data.phonetically_words.right.total,
                      })
                    )}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row> */}

          <Row className="mt-5">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Line
                ref={leftEarAudiogram}
                data={dataLeftEar}
                series={series}
                axes={axes}
                tooltip
                options={optionsLeftEar}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Line
                ref={rightEarAudiogram}
                data={dataRightEar}
                series={series}
                axes={axes}
                tooltip
                options={optionsRightEar}
              />
            </Col>
          </Row>
          <Row className="mt-5 text-center result__container ">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {ReactHtmlParser(t("Not_Substitute_Audiological_Clinical_Test"))}
            </Col>
          </Row>
        </div>
      </main>
      <footer className="footer"></footer>
    </Container>
  );
};

export default Result;
