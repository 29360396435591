import React, { useEffect } from "react";

import { Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

import StepTitle from "../../components/steptitle/StepTitle";

const ThankYou = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="mt-5">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="btn-container">
          <StepTitle
            text={ReactHtmlParser(t("Thank you for taking the test"))}
          />
        </Col>
      </Row>

      <Row className="mt-3 text-center font-bold">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="btn-container font-weight-bold"
        >
          {ReactHtmlParser(t("Your results will be emailed to you."))}
        </Col>
      </Row>

      <Row className="text-center">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="btn-container font-weight-bold"
        >
          {ReactHtmlParser(
            t(
              "If you have not received your results please check in your spam or junk folder."
            )
          )}
        </Col>
      </Row>

      <Row className="text-center">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="btn-container font-weight-bold"
        >
          {ReactHtmlParser(
            t("Call or email us if you still haven't received your results.")
          )}
        </Col>
      </Row>

      <Row className="mt-3 text-center">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="btn-container font-weight-bold"
        >
          {ReactHtmlParser(t("ID Team"))}
        </Col>
      </Row>

      <Row className="mt-3 text-center text-italic">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="btn-container font-weight-bold"
        >
          {ReactHtmlParser(t("THANKYOU_Disclaimer"))}
        </Col>
      </Row>
    </>
  );
};

export default ThankYou;
