import React from "react";

import { Button } from "react-bootstrap";

const ActionButton = React.forwardRef(
  ({ id, name, text, onClick, className = "", disable = false }, ref) => {
    return (
      <>
        <Button
          id={id}
          name={name}
          ref={ref}
          onClick={onClick}
          className={`actionButton ${className}`}
          disabled={disable ? "disabled" : ""}
        >
          {text}
        </Button>
      </>
    );
  }
);

export default ActionButton;
