import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

import { ActionButton } from "../../components/atomic";
import { Header } from "../../components/hearingtest";
import "./style.css";

import imagecover from "../../assets/images/homepage_background.png";
import googleAnalyticsG4Helpers from "../../helpers/googleAnalyticsG4Helpers";
import { useGA4React } from "ga-4-react";

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // const handleTakeFreeTest = (event) => {
  //   event.preventDefault();
  //   history.push("/instructions");
  // };

  const ga = useGA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_G4_ID);

  const handleGetStarted = (event) => {
    event.preventDefault();
    ga.gtag("start_test", "Start a new session");
    googleAnalyticsG4Helpers.event("start_test", "Start a new test");
    history.push("/instructions");
  };

  return (
    <Container fluid className="cover-container p-0 w-100 mw-100">
      <Header />
      <div id="content" className="content">
        <main className="main">
          <div className="hero">
            <img
              src={imagecover}
              loading="lazy"
              alt="homepage"
              className="cover-image"
            />

            <div className="container-fluid p-0">
              <div className="hero-content">
                <div className="hero-content__headline">
                  <h1 className="primary-text mb-2">
                    {t("Welcome to our free online hearing test")}
                  </h1>

                  <h6 className="secondary-text mb-3">
                    <span>
                      {t("Take a quick, easy test to find out whether you")}
                    </span>
                    <span>
                      {t("are suffering from noise induced hearing loss.")}
                    </span>
                  </h6>

                  <ActionButton
                    text={t("Get started")}
                    onClick={handleGetStarted}
                    className="btn-action-cover btn-getstarted"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <footer className="footer"></footer>
    </Container>
  );
};

export default Home;
