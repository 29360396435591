import { Row, Col } from "react-bootstrap";

const StepTitle = ({ text }) => {
  return (
    <>
      <Row className="steptitle__content">
        <Col>{text}</Col>
      </Row>
    </>
  );
};

export default StepTitle;
